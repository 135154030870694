<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages.inventory"
    />
  </div>
</template>

<script>
import {
  CargillCrudMetaView,
  responseToOptionsInContext
} from '@cargill/shared'
import service from '../api/inventoryQualitySpecificationService'
import _ from 'lodash'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service
    }
  },
  methods: {
    async getMeta() {
      const meta = await service.getMeta()
      const ctx = await service.getValidationContext()
      responseToOptionsInContext(ctx)
      const fieldsById = _.keyBy(meta.fields, (field) => field.id)
      fieldsById.inventory.options = ctx.inventoryOptions
      fieldsById.qualityGreatness.options = ctx.qualityGreatnessOptions
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
